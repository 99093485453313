import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import '../../styles/subscription.scss';
import '../../styles/toolTip.scss';
import { IPageProps, E_SORTORDER, sortObjects } from '../../sharedInterfaces';
import { api, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { IStep, ICustomer, IReseller, ISubscription, ISubscriptionLine, IPricelist, hasAnyPrivileges, EPrivileges, ISubscriptionStep, } from '../../apitypes/index';
import Dialog from '../../comps/dialog/Dialog';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import Select from 'react-select';
import { BsPlus } from 'react-icons/bs';
import { VscSearch, VscRefresh, VscEdit } from 'react-icons/vsc';
import { RxMagnifyingGlass, RxPencil1 } from 'react-icons/rx';
import { ImPrinter, ImWarning } from 'react-icons/im';
import { MdAlternateEmail } from "react-icons/md";
import { HiCheckCircle, HiXCircle } from "react-icons/hi";
import { TbCurrencyEuro, TbCurrencyEuroOff } from "react-icons/tb";
import { AiOutlineCopy } from "react-icons/ai";
import { IoEye, IoPlaySharp, IoStopSharp } from "react-icons/io5";
import { GrValidate } from "react-icons/gr";
import ToolTip from '../../comps/toolTip/ToolTip';
import EditSubscriptionForm from './EditSubscriptionForm';
import EditCustomerForm from '../customerManagement/EditCustomerForm';


interface IState {
  search: string,
  filter: string,
  filterByReseller: IReseller | null,
  filterByDates: number[],
  filterByStep: IStep | null,
  customers: ICustomer[],
  steps: IStep[],
  resellers: IReseller[],
  subscriptions: ISubscription[],
  pricelists: IPricelist[],
  editObject: ISubscription | null,
  editObjectIsNew: boolean,
  editObjectDuplicate: ISubscription | null,
  editCustomer: ICustomer | null,
  validateObject: ISubscription | null,
  isValid: boolean,
  uploadContractFile: null | File,
  uploadMandatPorta: null | File,
  uploadRIBFile: null | File,
  uploadInvoiceFile: null | File,
  sortBy: string,
  sortOrder: E_SORTORDER,
  printObject: ISubscription | null,
  emailTo: string,
  emailSubscription: boolean,
  emailPortability: boolean,
  subscriptionSteps: ISubscriptionStep[] | null,
  tooltip: string | null,
  tooltipX: number,
  tooltipY: number,
  showDownloadFilesSubscription: ISubscription | null
}


const NAME_LIMIT = 40;

class SubscriptionManagement extends Component<IPageProps, IState> {


  constructor(props: IPageProps) {
    super(props);

    this.state = {
      search: '',
      filter: "all",
      filterByReseller: null,
      filterByDates: [1609459200, moment().endOf('day').unix()],
      filterByStep: null,
      customers: [],
      resellers: [],
      steps: [],
      subscriptions: [],
      pricelists: [],
      editObject: null,
      editObjectIsNew: false,
      editObjectDuplicate: null,
      editCustomer: null,
      validateObject: null,
      isValid: false,
      uploadContractFile: null,
      uploadMandatPorta: null,
      uploadRIBFile: null,
      uploadInvoiceFile: null,
      sortBy: '',
      sortOrder: E_SORTORDER.ASC,
      printObject: null,
      emailTo: '',
      emailSubscription: true,
      emailPortability: true,
      subscriptionSteps: null,
      tooltip: null,
      tooltipX: 0,
      tooltipY: 0,
      showDownloadFilesSubscription: null,
    }
  }

  componentDidMount() {
    this.reloadObjects();

    const lastFilter = localStorage.getItem("lastSubscriptionFilter");
    if (lastFilter) {
      this.setState({
        filter: lastFilter
      })
    }


    if (window.location.search) {
      const parsedQuery = queryString.parse(window.location.search);
      if (parsedQuery.subscription) {
        const subid = parsedQuery.subscription as string;
        this.setState({
          search: subid
        });
        window.history.pushState({}, "", `${window.location.protocol}//${window.location.host}`);
      }
    }
  }

  sortObjectsBy(sortBy: string) {
    const { sortOrder, subscriptions } = this.state;
    let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
    if (sortBy === this.state.sortBy) {
      //Change sort order
      if (sortOrder === E_SORTORDER.ASC) {
        newSortOrder = E_SORTORDER.DESC;
      }
    }
    console.log(`Sort ${sortBy} ${newSortOrder}`);

    this.setState({
      sortOrder: newSortOrder,
      sortBy: sortBy,
      subscriptions: sortObjects(subscriptions, sortBy, newSortOrder)
    });
  }

  async reloadObjects() {

    showLoading(true);

    if (!api.currentUser) {
      return;
    }

    const reseller: IReseller | null = await api.reseller.getReseller(api.currentUser.reseller);
    if (!reseller) {
      showLoading(false);
      return;
    }

    const pricelists: IPricelist[] = [];
    for (const plid of reseller.pricelists) {
      const pl = await api.pricelist.getPricelist(plid);
      if (pl) {
        pricelists.push(pl);
      }
    }

    this.setState({
      resellers: await api.reseller.getResellers(),
      subscriptions: await api.subscription.getSubscriptions(),
      steps: await api.steps.getSteps(),
      pricelists: pricelists,
      editObject: null,
      editObjectIsNew: false,
      editObjectDuplicate: null,
      validateObject: null,
      subscriptionSteps: null,
      editCustomer: null
    }, async () => {
      await this.reloadCustomers();
      showLoading(false);
    });
  }

  async reloadCustomers() {

    showLoading(true);
    this.setState({
      customers: await api.customer.getCustomers()
    });
    showLoading(false);
  }

  async createNewObject() {
    this.setState({
      editObject: null,
      editObjectIsNew: true,
    })
  }

  async duplicateSubscription(obj: ISubscription) {
    showLoading(true);
    const newSubscriptionID = moment().format("YYYYMMDDHHmmssSSS");

    //load lines
    const lines = await api.subscription.getSubscriptionLines(obj.id);
    //immediatly save this object
    const duplicated = await api.subscription.createSubscription({
      ...obj,
      id: newSubscriptionID,
      date: api.unixTime(),
      validated: 0,
      canceled: 0,
      contractFile: null,
      mandatPorta: null,
      ribFile: null,
      billed: 0
    });
    if (!duplicated) {
      showError(i18n.s("subscriptionDuplicationError"));
      this.reloadObjects();
      showLoading(false);
      return;
    }

    const toDuplicateLines = lines.map((sl) => {
      return {
        ...sl,
        id: uuidv4(),
        subscription: duplicated.id,
        endTime: 0
      }
    })
    const duplicatedLines: ISubscriptionLine[] | null = await api.subscription.addOrUpdateSubscriptionLines(duplicated.id, toDuplicateLines)
    if (duplicatedLines) {
      await this.reloadObjects();
      showSuccess(i18n.s('subscriptionDuplicated'));
      this.setState({
        editObject: duplicated,
        editObjectIsNew: false,
        editObjectDuplicate: null
      });
    }
    else {
      showError(i18n.s("subscriptionDuplicationError"));
    }
    showLoading(false);
  }

  async setAsBilled(subscription: ISubscription, billed: boolean) {
    showLoading(true);
    if (billed || window.confirm(i18n.s("subscriptionUnbillWarning"))) {
      const sub = await api.subscription.changeSubscriptionBillState(subscription.id, billed ? moment().unix() : 0);
      if (sub) {
        showSuccess(i18n.s('success'));
        await this.reloadObjects();
      }
      else {
        showError(i18n.s('error'));
      }
    }
    showLoading(false);
  }

  async editSubscriptionSteps(subscription: ISubscription) {

    showLoading(true);
    const steps = await api.subscriptionStep.getSubscriptionSteps(subscription.id);
    this.setState({
      subscriptionSteps: steps ? steps : null
    });
    showLoading(false);
  }

  async generatePDF(id: string, type: string): Promise<void> {
    showLoading(true);
    let data = null;
    switch (type) {
      case "subscription":
        data = await api.subscription.getSubscriptionPDF(id);
        break;
      case "subscriptionMB":
        data = await api.subscription.getSubscriptionMBPDF(id);
        break;
      case "portability":
        data = await api.subscription.getPortabilityPDF(id);
        break;
      default: break;
    }
    console.log(data);
    if (data) {
      const blob = new Blob([data]);
      console.log(data);
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${type}_${id}.pdf`;
      document.body.appendChild(link);
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      link.remove();
      window.URL.revokeObjectURL(link.href);
    }
    else {
      showError(i18n.s("pdfGenerationError"));
    }
    showLoading(false);
  }

  renderObjectRow(obj: ISubscription): React.ReactNode {
    const { filter, customers, resellers, steps, filterByReseller, filterByDates, filterByStep } = this.state;

    const startDate = filterByDates[0];
    const endDate = filterByDates[1];
    if (obj.date < startDate || obj.date > endDate) {
      return; //filtrage par date
    }

    const nextStep = obj.nextStep ? steps.find(s => s.id === obj.nextStep) : null;

    if (filterByStep && obj.nextStep !== filterByStep.id) {
      return; //filtrage par étape actif
    }

    switch (filter) {
      case "all":
        break;
      case "notValidatedOnly":
        if (obj.validated > 0) return;
        if (obj.canceled > 0) return;
        break;
      case "validatedNotDelivered":
        if (obj.validated === 0) return;
        if (obj.nextStep !== undefined && obj.nextStep === null) return;
        if (obj.canceled > 0) return;
        break;
      case "deliveredNotBilled":
        if (obj.validated === 0) return;
        if (obj.nextStep !== undefined && obj.nextStep !== null) return;
        if (obj.billed > 0) return;
        if (obj.canceled > 0) return;
        break;
      case "billedAndActive":
        if (obj.validated === 0) return;
        if (obj.nextStep !== undefined && obj.nextStep !== null) return;
        if (obj.billed === 0) return;
        if (obj.canceled > 0) return;
        break;
      case "closed":
        if (obj.canceled === 0) return;
        break;
      default: break;
    }
    const search = this.state.search.trim().toUpperCase();

    const customer = customers.find(c => c.id === obj.customer);
    const reseller = customer ? resellers.find(r => r.id === customer.reseller) : null;

    if (customer && filterByReseller && filterByReseller.id !== customer.reseller) {
      return null;
    }

    if (search !== "") {
      let doNoDisplay = true;
      if (obj.id !== null && obj.id.toString().toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (reseller && reseller.company.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (customer && customer.company.toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (obj.observations.join('\n').toUpperCase().includes(search)) {
        doNoDisplay = false;
      }
      if (doNoDisplay) {
        return null;
      }
    }



    return <tr key={obj.id} >
      <td>
        <div className='buttons'>
          <button onClick={async () => {
            this.setState({
              editObject: obj,
              editObjectIsNew: false
            });
          }}>
            {
              obj.validated === 1 || hasAnyPrivileges(api.currentUser, EPrivileges.WRITE_SUBSCRIPTIONS) === false
                ? <IoEye />
                : <VscEdit />
            }
          </button>
          {
            obj.validated > 0 && hasAnyPrivileges(api.currentUser, EPrivileges.SUPER_ADMIN)
              ? (obj.canceled > 0
                ? <button title={i18n.s("subscriptionUncancel")} className='success' onClick={async () => {
                  if (window.confirm(i18n.s("subscriptionUncancelValidation", [obj.id]))) {
                    showLoading(true);
                    if (await api.subscription.cancelSubscription(obj.id, false)) {
                      this.reloadObjects();
                    }
                    else {
                      showError(i18n.s("subscriptionCancelError"));
                    }
                    showLoading(false);
                  }
                }}>
                  <IoPlaySharp />
                </button>
                : <button title={i18n.s("subscriptionCancel")} className='danger' onClick={async () => {
                  if (window.confirm(i18n.s("subscriptionCancelValidation", [obj.id]))) {
                    showLoading(true);
                    if (await api.subscription.cancelSubscription(obj.id, true)) {
                      this.reloadObjects();
                    }
                    else {
                      showError(i18n.s("subscriptionCancelError"));
                    }
                    showLoading(false);
                  }
                }}>
                  <IoStopSharp />
                </button>
              )
              : null
          }
          {
            hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_SUBSCRIPTIONS])
              ? <button title={i18n.s("subscriptionDuplicate")} className='info' onClick={async () => { this.duplicateSubscription(obj) }}>
                <AiOutlineCopy />
              </button>
              : null
          }
        </div>
      </td>
      <td>{obj.id}</td>
      <td
        onMouseEnter={(e) => {
          if (reseller && reseller.company.length>NAME_LIMIT) {
            this.setState({ tooltip: reseller.company, tooltipX: e.pageX, tooltipY: e.pageY })
          }
        }}
        onMouseLeave={() => { this.setState({ tooltip: null }) }}
      >
        {
          reseller && reseller.company.length > NAME_LIMIT 
          ?  `${reseller?.company.substring(0,NAME_LIMIT)}...`
          : reseller?.company
        }
      </td>
      <td
        onMouseEnter={(e) => {
          if (customer && customer.company.length>NAME_LIMIT) {
            this.setState({ tooltip: customer.company, tooltipX: e.pageX, tooltipY: e.pageY })
          }
        }}
        onMouseLeave={() => { this.setState({ tooltip: null }) }}
        onClick={() => {
          if (customer) {
            this.setState({ editCustomer: customer })
          }
        }}
      >
        {
          customer && customer.company.length > NAME_LIMIT 
          ?  `${customer?.company.substring(0,NAME_LIMIT)}...`
          : customer?.company
        }
      </td>
      <td className='center'>
        <div>
          {
            obj.validated === 0
              ? <div className='buttons' >
                <button className='info' title={i18n.s("exportPDFSubscription")} onClick={async () => {
                  this.setState({
                    printObject: obj,
                    emailTo: `${reseller?.email ? reseller.email.split(',') + '\r\n' : ''}${obj.invoiceReseller === false && customer?.email ? customer.email.split(',') + "\r\n" : ""}commercial@ipconnect.fr`,
                    emailSubscription: true,
                    emailPortability: true
                  })
                }}>
                  <ImPrinter />
                </button>
              </div>
              : <button className='success' onClick={async () => {
                this.setState({
                  showDownloadFilesSubscription: obj
                });
              }}>
                <ImPrinter />
              </button>
          }
        </div>
      </td>
      <td className='center'>
        <div>
          {
            obj.alert.join('').trim() !== ""
              ? <ImWarning className='danger' onMouseEnter={(e) => { this.setState({ tooltip: obj.alert.join('\n'), tooltipX: e.pageX, tooltipY: e.pageY }) }} onMouseLeave={() => { this.setState({ tooltip: null }) }} />
              : null
          }
        </div>
      </td>
      <td>
        {
          obj.validated > 0
            ? hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN])
              ? <span className='inline'>
                <button className='info' onClick={() => { this.setState({ validateObject: obj, isValid: true }) }}><RxPencil1 /></button>
                <label>{moment.unix(obj.validated).format("DD/MM/YYYY")}</label>
              </span>
              : moment.unix(obj.validated).format("DD/MM/YYYY")
            : hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.VALIDATE_SUBSCRIPTION])
              ? <Fragment>
                <button title={i18n.s("subscriptionValidate")} className='success' onClick={() => { this.setState({ validateObject: obj, isValid: false }) }}>
                  <GrValidate />
                  <label>{i18n.s('subscriptionValidate')}</label>
                </button>
              </Fragment>
              : null
        }
      </td>
      <td className={obj.nextStep !== undefined && obj.nextStep !== null ? 'danger' : 'success'}>
        {
          obj.validated > 0
            ? <div className='deliveree'>
              {
                hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.SET_SUBSCRIPTION_STEPS])
                  ? <button onClick={() => {
                    this.editSubscriptionSteps(obj);
                  }}><RxMagnifyingGlass /></button>
                  : null
              }
              {
                obj.nextStep !== undefined && obj.nextStep === null
                  ? <Fragment>
                    <label>{i18n.s('yes')}</label>
                    <HiCheckCircle />
                  </Fragment>
                  : <Fragment>
                    <label>{i18n.s('no')}</label>
                    <HiXCircle />
                  </Fragment>
              }
            </div>
            : null
        }
      </td>
      <td
        onMouseEnter={(e) => {
          if (nextStep && nextStep.step.length>NAME_LIMIT) {
            this.setState({ tooltip: nextStep.step, tooltipX: e.pageX, tooltipY: e.pageY })
          }
        }}
        onMouseLeave={() => { this.setState({ tooltip: null }) }}
      >
       {
          nextStep && nextStep.step.length > NAME_LIMIT 
          ?  `${nextStep.step.substring(0,NAME_LIMIT)}...`
          : nextStep?.step
        }
      </td>
      <td>{obj.nextStepDatePrev ? moment.unix(obj.nextStepDatePrev).format("DD/MM/YYYY") : null}</td>
      <td className='center'>
        <div>
          {
            obj.validated > 0 && obj.nextStep === null
              ? obj.billed > 0
                ? hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN])
                  ? <button className='success' onClick={() => { this.setAsBilled(obj, false) }}><TbCurrencyEuro /></button>
                  : <TbCurrencyEuro className='success' />
                : hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN])
                  ? <button className='danger' onClick={() => { this.setAsBilled(obj, true) }}><TbCurrencyEuroOff /></button>
                  : <TbCurrencyEuroOff className='danger' />
              : null
          }
        </div>
      </td>
    </tr>
  }

  renderObjectValidation(): React.ReactNode {
    const { validateObject, isValid, uploadContractFile, uploadMandatPorta, uploadRIBFile, uploadInvoiceFile } = this.state;

    if (validateObject === null || api.currentUser === null || api.currentUser.reseller === null) {
      return null;
    }


    return <Dialog
      title={i18n.s("validateSubscription")}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
      onCancel={() => {
        this.setState({
          validateObject: null,
        });
      }}
      onOK={async () => {
        showLoading(true);
        if (await api.subscription.validateSubscription(validateObject.id, isValid, uploadContractFile, uploadMandatPorta, uploadRIBFile, uploadInvoiceFile)) {
          showSuccess(i18n.s('success'));
          this.reloadObjects(); //perhaps now open the line editor ?
        }
        else {
          showError(i18n.s("subscriptionValidationError"));
        }
        showLoading(false);
      }}
    >
      <div className='edit-object'>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('isSubscriptionValid')}</label></div>
            <select value={isValid ? 1 : 0} onChange={(e) => {
              this.setState({
                isValid: e.currentTarget.value === "1"
              })
            }} >
              <option value="1">{i18n.s("yes")}</option>
              <option value="0">{i18n.s("no")}</option>
            </select>
          </div>

          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('subscriptionLoadPDF')}</label></div>
            <div className='file-upload'>
              <input type="file" multiple={false} onChange={(e) => {
                if (e.currentTarget.files && e.currentTarget.files.length > 0) {
                  this.setState({ uploadContractFile: e.currentTarget.files[0] })
                }
              }} />
              <span>
                {
                  validateObject.contractFile
                    ? i18n.s('subscriptionFileAlreadyUploaded')
                    : ''
                }
              </span>
            </div>

          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('subscriptionLoadMandatPorta')}</label></div>
            <div className='file-upload'>
              <input type="file" multiple={false} onChange={(e) => {
                if (e.currentTarget.files && e.currentTarget.files.length > 0) {
                  this.setState({ uploadMandatPorta: e.currentTarget.files[0] })
                }
              }} />
              <span>
                {
                  validateObject.mandatPorta
                    ? i18n.s('subscriptionFileAlreadyUploaded')
                    : ''
                }
              </span>
            </div>
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('subscriptionRIBFile')}</label></div>
            <div className='file-upload'>
              <input type="file" multiple={false} onChange={(e) => {
                if (e.currentTarget.files && e.currentTarget.files.length > 0) {
                  this.setState({ uploadRIBFile: e.currentTarget.files[0] })
                }
              }} />
              <span>
                {
                  validateObject.ribFile
                    ? i18n.s('subscriptionFileAlreadyUploaded')
                    : ''
                }
              </span>
            </div>
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('subscriptionInvoiceFile')}</label></div>
            <div className='file-upload'>
              <input type="file" multiple={false} onChange={(e) => {
                if (e.currentTarget.files && e.currentTarget.files.length > 0) {
                  this.setState({ uploadInvoiceFile: e.currentTarget.files[0] })
                }
              }} />
              <span>
                {
                  validateObject.invoiceFile
                    ? i18n.s('subscriptionFileAlreadyUploaded')
                    : ''
                }
              </span>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  }

  renderSubscriptionStepsEdition(): React.ReactNode {
    const { subscriptionSteps, steps } = this.state;

    if (subscriptionSteps === null || api.currentUser === null || api.currentUser.reseller === null) {
      return null;
    }

    return <Dialog
      title={i18n.s("subscriptionStepEdition")}
      showOkButton={true}
      showCloseButton={false}
      onOK={async () => {
        const { subscriptionSteps } = this.state;
        if (subscriptionSteps) {
          showLoading(true);
          for (const ss of subscriptionSteps) {
            if (ss.needSave) {
              if (await api.subscriptionStep.updateSubscriptionStep(ss) === null) {
                showError(i18n.s('error'));
              }
            }
          }
        }

        this.reloadObjects();
        showLoading(false);
      }}
    >
      <div className='edit-object'>
        <h3>{i18n.s("subscriptionSteps")}</h3>
        <table>
          <thead>
            <tr>
              <th>{i18n.s('stepState')}</th>
              <th>{i18n.s('stepStep')}</th>
              <th>{i18n.s('stepQty')}</th>
              <th>{i18n.s('stepPriority')}</th>
              <th>{i18n.s('stepDatePrev')}</th>
              <th>{i18n.s('stepDone')}</th>
              <th>{i18n.s('stepNotes')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              subscriptionSteps.map((subStep) => {
                const step = steps.find(s => s.id === subStep.step);

                return <tr>
                  <td>
                    {
                      subStep.done === 0
                        ? <label className='inline danger'><HiXCircle />{i18n.s('stepTodo')}</label>
                        : <label className='inline success'><HiCheckCircle />{i18n.s('stepDone')}</label>
                    }
                  </td>
                  <td>{step?.step}</td>
                  <td>{subStep.qty}</td>
                  <td>{subStep.priority}</td>
                  <td>
                    {subStep.done > 0
                      ? subStep.datePrev > 0 ? moment.unix(subStep.datePrev).format("DD/MM/YYYY") : ""
                      : <input type="date" value={subStep.datePrev > 0 ? moment.unix(subStep.datePrev).format("YYYY-MM-DD") : ""} onChange={(e) => {
                        this.setState({
                          subscriptionSteps: subscriptionSteps.map((ss) => {
                            if (ss.subscription === subStep.subscription && ss.step === subStep.step) {
                              return {
                                ...subStep,
                                datePrev: moment(e.currentTarget.value, "YYYY-MM-DD").unix(),
                                needSave: true
                              }
                            }
                            return ss;
                          })
                        })
                      }} />
                    }

                  </td>
                  <td>{subStep.done > 0 ? moment.unix(subStep.done).format("DD/MM/YYYY") : ''}</td>
                  <td>
                    {
                      subStep.done > 0
                        ? subStep.notes
                        : <input type='text' value={subStep.notes} onChange={(e) => {
                          this.setState({
                            subscriptionSteps: subscriptionSteps.map((ss) => {
                              if (ss.subscription === subStep.subscription && ss.step === subStep.step) {
                                return {
                                  ...subStep,
                                  notes: e.currentTarget.value,
                                  needSave: true
                                }
                              }
                              return ss;
                            })
                          })
                        }} />
                    }
                  </td>
                  <td>
                    {
                      subStep.done === 0
                        ? <button className='deliveree success' onClick={() => {
                          this.setState({
                            subscriptionSteps: subscriptionSteps.map((ss) => {
                              if (ss.subscription === subStep.subscription && ss.step === subStep.step) {
                                return {
                                  ...subStep,
                                  done: moment().unix(),
                                  needSave: true
                                }
                              }
                              return ss;
                            })
                          })
                        }}>{i18n.s('stepMarkAsDone')}</button>
                        : <button className='deliveree danger' onClick={() => {
                          this.setState({
                            subscriptionSteps: subscriptionSteps.map((ss) => {
                              if (ss.subscription === subStep.subscription && ss.step === subStep.step) {
                                return {
                                  ...subStep,
                                  done: 0,
                                  needSave: true
                                }
                              }
                              return ss;
                            })
                          })
                        }}>{i18n.s('stepMarkAsUndone')}</button>
                    }
                  </td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </Dialog>
  }

  renderPrintObject(): React.ReactNode {
    const { printObject, emailTo, emailSubscription, emailPortability } = this.state;
    if (printObject === null) {
      return null;
    }

    return <Dialog
      title={i18n.s("subscriptionPrint")}
      showOkButton={true}
      showCancelButton={false}
      showCloseButton={false}
      onOK={async () => {
        this.setState({
          printObject: null
        });
      }}
    >
      <div className='edit-object'>
        <h3>{i18n.s("subscriptionExportToPDF")}</h3>
        <div className='buttons'>
          <button onClick={() => {
            this.generatePDF(printObject.id, printObject.invoiceReseller ? "subscriptionMB" : "subscription");
          }}>
            <ImPrinter /><label>{i18n.s('subscriptionPDF')}</label>
          </button>
          <button onClick={() => {
            this.generatePDF(printObject.id, "portability");
          }}>
            <ImPrinter /><label>{i18n.s('mandatPDF')}</label>
          </button>
        </div>

        <h3>{i18n.s("subscriptionEmailPDFPartner")}</h3>
        <div className='edit-object-form'>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('emailTo')}</label></div>
            <textarea onChange={(e) => {
              this.setState({
                emailTo: e.currentTarget.value.replace(/;/g, "\r\n").replace(/,/g, "\r\n").replace(/\s/g, "\r\n")
              })
            }} value={emailTo}></textarea>
          </div>
          <div className='row input-with-label'>
            <div><RxPencil1 /> <label>{i18n.s('emailType')}</label></div>
            <ul>
              <li><input type='checkbox' checked={emailSubscription} onChange={(e) => { this.setState({ emailSubscription: e.currentTarget.checked }) }} /> {i18n.s('subscriptionPDF')}</li>
              <li><input type='checkbox' checked={emailPortability} onChange={(e) => { this.setState({ emailPortability: e.currentTarget.checked }) }} /> {i18n.s('mandatPDF')}</li>
            </ul>
          </div>
        </div>


        <button onClick={async () => {
          showLoading(true);
          const resmail = await api.subscription.sendSubscriptionFiles(printObject.id, emailTo.split('\r\n'), emailSubscription, emailPortability)
          if (resmail) {
            showSuccess(i18n.s('success'));
          }
          else {
            showError(i18n.s("sendMailError"));
          }
          showLoading(false);
        }}>
          <MdAlternateEmail /><label>{i18n.s('sendByMail')}</label>
        </button>
      </div>
    </Dialog>
  }

  render() {
    const { subscriptions, search, filter, editObject, editObjectIsNew,
      validateObject, printObject, subscriptionSteps, tooltip, tooltipX, tooltipY,
      showDownloadFilesSubscription, editCustomer, resellers, filterByReseller, filterByDates,
      filterByStep, steps } = this.state;

    return (
      <Fragment>
        <div className='management subscriptions'>
          <h1>{i18n.s("subscriptions")}</h1>
          <div className='search form'>
            <div className='input-with-label'>
              <div><VscSearch /></div>
              <input type="text" value={search} onChange={(e) => { this.setState({ search: e.currentTarget.value }) }} />
            </div>
            <div className='filters inline'>
              <div className='inline'>
                <label>{i18n.s("filterSubscriptionByStatus")}</label>
                <select value={filter} onChange={(e) => {
                  localStorage.setItem("lastSubscriptionFilter", e.currentTarget.value);
                  this.setState({ filter: e.currentTarget.value });
                }}>
                  <option value="all">{i18n.s('all')}</option>
                  <option value="notValidatedOnly">{i18n.s('notValidatedOnly')}</option>
                  <option value="validatedNotDelivered">{i18n.s('validatedNotDelivered')}</option>
                  <option value="deliveredNotBilled">{i18n.s('deliveredNotBilled')}</option>
                  <option value="billedAndActive">{i18n.s('billedAndActive')}</option>
                  <option value="closed">{i18n.s('closed')}</option>
                </select>
              </div>

              <div className='inline'>
                <label>{i18n.s("filterSubscriptionByReseller")}</label>
                <Select
                  className='react-select'
                  classNamePrefix='react-select'
                  isClearable
                  value={filterByReseller ? { value: filterByReseller, label: filterByReseller.company } : null}
                  onChange={(e) => {
                    this.setState({
                      filterByReseller: e ? e.value : null
                    })
                  }}
                  options={resellers.map((r: IReseller) => {
                    return { value: r, label: r.company }
                  })}
                />
              </div>

              <div className='inline'>
                <label>{i18n.s("filterByStep")}</label>
                <Select
                  className='react-select'
                  classNamePrefix='react-select'
                  isClearable
                  value={filterByStep ? { value: filterByStep, label: filterByStep.step } : null}
                  onChange={(e) => {
                    this.setState({
                      filterByStep: e ? e.value : null
                    })
                  }}
                  options={steps.map((s: IStep) => {
                    return { value: s, label: s.step }
                  })}
                />
              </div>


              <div className='inline'>
                <label>{i18n.s("filterSubscriptionByDate")}</label>
                <input type="date" value={filterByDates[0] !== 0 ? moment.unix(filterByDates[0]).format("YYYY-MM-DD") : ""} onChange={(e) => {
                  this.setState({
                    filterByDates: [
                      moment(e.currentTarget.value, "YYYY-MM-DD").unix(),
                      filterByDates[1]
                    ]
                  })
                }} />
                <label>{i18n.s("and")}</label>
                <input type="date" value={filterByDates[1] !== 0 ? moment.unix(filterByDates[1]).format("YYYY-MM-DD") : ""} onChange={(e) => {
                  this.setState({
                    filterByDates: [
                      filterByDates[0],
                      moment(e.currentTarget.value, "YYYY-MM-DD").unix()
                    ]
                  })
                }} />
              </div>
            </div>
          </div>

          <div className='button-actions '>
            <button onClick={() => { this.reloadObjects() }} title={i18n.s("reload")}><VscRefresh /></button>
            {
              hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_SUBSCRIPTIONS])
                ? <Fragment>
                  <button className='success' onClick={() => { this.createNewObject() }} title={i18n.s("createSubscription")}><BsPlus /></button>
                </Fragment>
                : null
            }
          </div>


          <div>
            <table>
              <thead>
                <tr >
                  <th style={{ width: "30px", textAlign: "center" }}></th>
                  <th style={{ width: "50px" }}>{i18n.s("subscriptionID")}</th>
                  <th className='sortable' onClick={() => { this.sortObjectsBy("reseller") }}>{i18n.s("resellerName")}</th>
                  <th className='sortable' onClick={() => { this.sortObjectsBy("customer") }}>{i18n.s("customerName")}</th>
                  <th style={{ width: "50px" }}></th>
                  <th style={{ width: "50px" }}></th>
                  <th className='sortable' onClick={() => { this.sortObjectsBy("validated") }} style={{ width: "130px" }}>{i18n.s("subscriptionValidated")}</th>
                  <th style={{ width: "100px" }}>{i18n.s("subscriptionDelivered")}</th>
                  <th className='sortable' onClick={() => { this.sortObjectsBy("nextStep") }} style={{ width: "100px" }}>{i18n.s("nextStep")}</th>
                  <th className='sortable' onClick={() => { this.sortObjectsBy("nextStepDatePrev") }} style={{ width: "100px" }}>{i18n.s("nextStepPrev")}</th>
                  <th className='sortable' onClick={() => { this.sortObjectsBy("billed") }} style={{ width: "80px" }}>{i18n.s("subscriptionBilled")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  subscriptions.map((obj) => {
                    return this.renderObjectRow(obj)
                  })
                }
              </tbody>
            </table>
          </div>

          {
            editObject !== null || editObjectIsNew
              ? <EditSubscriptionForm
                editSubscription={editObject ? editObject.id : null}
                initialSubscriptionLines={[]}
                initialSubscriptionObservations={null}
                onCancel={() => { this.setState({ editObject: null, editObjectDuplicate: null, editObjectIsNew: false }) }}
                onSave={() => { this.reloadObjects() }}
                onDeleted={() => { this.reloadObjects() }}
                readOnly={editObject ? editObject.validated > 0 : false}
              />
              : null
          }

          {validateObject !== null ? this.renderObjectValidation() : null}

          {printObject !== null ? this.renderPrintObject() : null}

          {subscriptionSteps !== null ? this.renderSubscriptionStepsEdition() : null}

          {
            tooltip ? <ToolTip text={tooltip} x={tooltipX} y={tooltipY} /> : null
          }

          {
            editCustomer ? <EditCustomerForm editCustomerID={editCustomer.id} onCancel={() => { this.setState({ editCustomer: null }) }} onSave={() => { this.reloadObjects() }} siret='' /> : null
          }

          {
            showDownloadFilesSubscription
              ? <Dialog
                title={i18n.s('downloadSelector')}
                onCancel={() => { this.setState({ showDownloadFilesSubscription: null }) }}
                onOK={() => { this.setState({ showDownloadFilesSubscription: null }) }}
                showCloseButton={false}
                showCancelButton={true}
              >
                <div className='download-selector'>
                  <ul>
                    <li>
                      <div className='inline'>
                        <label>{i18n.s('allFiles')} :</label>
                        <button onClick={async () => {
                          const blob = await api.subscription.downloadSubscriptionFiles(showDownloadFilesSubscription.id);
                          if (blob) {
                            let a = document.createElement('a');
                            a.href = window.URL.createObjectURL(blob);
                            a.download = `contract_${showDownloadFilesSubscription.id}`;
                            a.dispatchEvent(new MouseEvent('click'));
                            a.remove();
                          }
                          else {
                            showError(i18n.s("subscriptionDownloadFileError"))
                          }
                        }}>
                          {i18n.s('download')}
                        </button>
                      </div>
                    </li>
                    <li>
                      <div className='inline'>
                        <label>{i18n.s('subscriptionPDF')} :</label>
                        {
                          showDownloadFilesSubscription.contractFile
                            ? <button onClick={async () => {
                              const blob = await api.subscription.downloadSubscriptionFile(showDownloadFilesSubscription.id, "contrat");
                              if (blob) {
                                let a = document.createElement('a');
                                a.href = window.URL.createObjectURL(blob);
                                a.download = `contract_${showDownloadFilesSubscription.id}`;
                                a.dispatchEvent(new MouseEvent('click'));
                                a.remove();
                              }
                              else {
                                showError(i18n.s("subscriptionDownloadFileError"))
                              }
                            }}>
                              {i18n.s('download')}
                            </button>
                            : <label>{i18n.s('missingFile')}</label>
                        }
                      </div>
                    </li>
                    <li>
                      <div className='inline'>
                        <label>{i18n.s('mandatPDF')} :</label>
                        {
                          showDownloadFilesSubscription.mandatPorta
                            ? <button onClick={async () => {
                              const blob = await api.subscription.downloadSubscriptionFile(showDownloadFilesSubscription.id, "mandat");
                              if (blob) {
                                let a = document.createElement('a');
                                a.href = window.URL.createObjectURL(blob);
                                a.download = `mandat_${showDownloadFilesSubscription.id}`;
                                a.dispatchEvent(new MouseEvent('click'));
                                a.remove();
                              }
                              else {
                                showError(i18n.s("subscriptionDownloadFileError"))
                              }
                            }}>
                              {i18n.s('download')}
                            </button>
                            : <label>{i18n.s('missingFile')}</label>
                        }
                      </div>
                    </li>
                    <li>
                      <div className='inline'>
                        <label>{i18n.s('RIBPDF')} :</label>
                        {
                          showDownloadFilesSubscription.ribFile
                            ? <button onClick={async () => {
                              const blob = await api.subscription.downloadSubscriptionFile(showDownloadFilesSubscription.id, "RIB");
                              if (blob) {
                                let a = document.createElement('a');
                                a.href = window.URL.createObjectURL(blob);
                                a.download = `rib_${showDownloadFilesSubscription.id}`;
                                a.dispatchEvent(new MouseEvent('click'));
                                a.remove();
                              }
                              else {
                                showError(i18n.s("subscriptionDownloadFileError"))
                              }
                            }}>
                              {i18n.s('download')}
                            </button>
                            : <label>{i18n.s('missingFile')}</label>
                        }
                      </div>
                    </li>
                    <li>
                      <div className='inline'>
                        <label>{i18n.s('invoiceFile')} :</label>
                        {
                          showDownloadFilesSubscription.invoiceFile
                            ? <button onClick={async () => {
                              const blob = await api.subscription.downloadSubscriptionFile(showDownloadFilesSubscription.id, "INVOICE");
                              if (blob) {
                                let a = document.createElement('a');
                                a.href = window.URL.createObjectURL(blob);
                                a.download = `facture_${showDownloadFilesSubscription.id}`;
                                a.dispatchEvent(new MouseEvent('click'));
                                a.remove();
                              }
                              else {
                                showError(i18n.s("subscriptionDownloadFileError"))
                              }
                            }}>
                              {i18n.s('download')}
                            </button>
                            : <label>{i18n.s('missingFile')}</label>
                        }
                      </div>
                    </li>
                  </ul>
                </div>
              </Dialog>
              : null
          }

        </div>
      </Fragment>

    );
  }

}

export default SubscriptionManagement;