import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/fileExplorer.scss';
import i18n from '../../i18n/I18n';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { filesize } from "filesize";
import Dialog from '../../comps/dialog/Dialog';
import { IPageProps, E_SORTORDER, sortObjects } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import { EPrivileges, IFile, hasAnyPrivileges } from '../../apitypes/index';

import { VscSearch, VscNewFolder, VscCloudUpload, VscRefresh } from 'react-icons/vsc';
import { MdExpandMore, MdChevronRight } from 'react-icons/md';
import { FcFolder, FcFile } from 'react-icons/fc';
import { IoTrashBinOutline } from 'react-icons/io5';
import { BsVectorPen } from 'react-icons/bs';


interface IState {
  directory: string,
  search: string,
  searchResults: Array<any> | null,
  dirTree: IFile | null,
  fileList: Array<IFile> | null,
  currentPath: string,
  expanded: Array<string>,
  showDeleteFilesDialog: boolean,
  selectAllFiles: boolean,
  selectFiles: Array<string>,
  showNewFolderDialog: boolean,
  newFolderName: string,
  showUploadFileDialog: boolean,
  uploadFiles: any | null,
  uploadProgress: number | null,
  uploadRemainingSeconds: number,
  uploadBps: number,
  sortBy: string,
  sortOrder: E_SORTORDER,
  currentDirID: string
}

class FileExplorer extends Component<IPageProps, IState> {
  searchFileTimer: any | null = null;

  constructor(props: IPageProps) {
    super(props);

    this.state = {
      directory: '',
      search: '',
      searchResults: null,
      dirTree: null,
      fileList: null,
      currentPath: '',
      expanded: [''],
      showDeleteFilesDialog: false,
      selectAllFiles: false,
      selectFiles: [],
      showNewFolderDialog: false,
      newFolderName: '',
      showUploadFileDialog: false,
      uploadFiles: null,
      uploadProgress: null,
      uploadRemainingSeconds: 0,
      uploadBps: 0,
      sortBy: '',
      sortOrder: E_SORTORDER.ASC,
      currentDirID: ""
    }
  }


  async componentDidMount(): Promise<void> {
    this.buildFileTable("");
  }

  sortObjectsBy(sortBy: string) {
    const { fileList, sortOrder } = this.state;
    let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
    if (sortBy === this.state.sortBy) {
      //Change sort order
      if (sortOrder === E_SORTORDER.ASC) {
        newSortOrder = E_SORTORDER.DESC;
      }
    }
    console.log(`Sort ${sortBy} ${newSortOrder}`);
    if (fileList !== null) {
      this.setState({
        sortOrder: newSortOrder,
        sortBy: sortBy,
        fileList: sortObjects(fileList, sortBy, newSortOrder)
      });
    }
  }


  epochToLocalTime(epoch: number) {
    const d = new Date(0);
    d.setUTCSeconds(epoch);
    return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
  }

  toHumanFileSize(size: number): string {
    const val = filesize(size, { base: 10, standard: "jedec" });
    return val.toString();
  }

  toHumanDuration(seconds: number): string {
    var secs = seconds;
    var mins = 0;
    var hours = 0;

    while (secs >= 60) {
      mins++;
      secs -= 60;
      if (mins === 60) {
        hours++;
        mins = 0;
      }
    }

    return `${hours > 9 ? hours : `0${hours}`}:${mins > 9 ? mins : `0${mins}`}:${secs > 9 ? secs : `0${secs}`}`;
  }



  findDir(id: string, fromTree: Array<any> | null): any {
    if (fromTree === null) {
      return null;
    }

    for (var d of fromTree) {
      if (d.isDirectory) {
        if (d.id === id) {
          return d;
        }
        if (d.childrens) {
          const foundDir = this.findDir(id, d.childrens);
          if (foundDir !== null) {
            return foundDir;
          }
        }
      }
    }

    return null;
  }


  expandCollapseDir(id: any) {
    if (id === '') {
      return;
    }
    const { expanded } = this.state;
    //do we need to expand or collapse ?
    const index = expanded.findIndex(e => e === id);
    return index === -1 ? this.expandDir(id) : this.collapseDir(id);
  }


  expandDir(id: string) {
    const { expanded } = this.state;
    if (expanded.findIndex(d => d === id) === -1) {
      console.log(`Expanding ${id}`);
      const newExpanded = [
        ...expanded,
        id
      ];
      this.setState({
        expanded: newExpanded
      }, () => {
        console.log(this.state.expanded);
      });
    }

  }

  collapseDir(id: string) {
    const { expanded } = this.state;
    if (expanded.findIndex(d => d === id) !== -1) {
      console.log(`Collapsing ${id}`);
      const newExpanded = expanded.filter(e => e !== id);
      this.setState({
        expanded: newExpanded
      }, () => {
        console.log(this.state.expanded);
      });
    }
  }


  goBack() {
    const { currentPath } = this.state;
    if (currentPath.indexOf('/') > -1) {
      const parentPath = currentPath.substring(0, currentPath.lastIndexOf('/'));
      this.buildFileTable(parentPath);
    }
    else {
      this.buildFileTable("");
    }
  }



  renderTreeDirectories(dir: any): React.ReactNode {
    return <TreeItem
      key={dir.id}
      itemId={dir.id}
      label={
        <label
          onDragOver={(event) => {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
          }}
          onDragLeave={(event) => {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'none';
          }}
          onDrop={async (event) => {
            const { currentPath } = this.state;
            event.preventDefault();
            const file: any = JSON.parse(event.dataTransfer.getData("application/x-file-id"));
            const result = await api.file.copyFileTo(file.id, `${dir.id}/${file.name}`, true);
            if (result === false) {
              showError(i18n.s("errorMoveFile"));
            }
            this.buildFileTable(currentPath);
          }}>
          {dir.name}
        </label>}
      onClick={() => {
        this.expandCollapseDir(dir.id);
        this.buildFileTable(dir.id);
      }}
    >
      {
        dir.childrens.map((child: any) => {
          return this.renderTreeDirectories(child)
        })
      }
    </TreeItem>
  }


  async buildFileTable(path: string, updateDirTree: boolean = false) {

    const { dirTree } = this.state;

    showLoading(true);

    const files = await api.file.getFileList(path);

    this.setState({
      dirTree: dirTree === null || updateDirTree ? await api.file.getDirTree('') : dirTree,
      currentPath: path,
      selectAllFiles: false,
      showDeleteFilesDialog: false,
      selectFiles: [],
      showNewFolderDialog: false,
      newFolderName: "",
      showUploadFileDialog: false,
      uploadFiles: null,
      uploadBps: 0,
      uploadProgress: null,
      uploadRemainingSeconds: 0,
      fileList: files,
      currentDirID: path,
    });
    showLoading(false);
  }


  searchFiles(value: string) {

    this.setState({ search: value.trim() });


    clearTimeout(this.searchFileTimer);
    if (value.trim() !== "") {
      this.searchFileTimer = setTimeout(async () => {
        showLoading(true);
        const foundFiles = await api.file.findFiles(value.trim());
        this.setState({
          searchResults: foundFiles,
        });
        showLoading(false);
      }, 500);
    }
    else {
      this.setState({
        searchResults: null
      })
      showLoading(false);
    }
  }


  renderSearchResults(): React.ReactNode {
    const { search, searchResults } = this.state;

    return <div className='search-results'>
      {
        i18n.s("searchFileResult", [search, searchResults !== null ? searchResults.filter(sr => sr.isDirectory === false).length.toString() : "0"])
      }
      <table>
        <thead>
          <tr>
            <th></th>
            <th style={{ width: "24px", textAlign: "center" }}></th>
            <th style={{ minWidth: "150px" }}>{i18n.s("fileName")}</th>
            <th style={{ minWidth: "180px", width: '180px', maxWidth: "180px" }}>{i18n.s("modified")}</th>
            <th style={{ minWidth: "100px", width: '100px', maxWidth: "100px" }}>{i18n.s("size")}</th>
          </tr>
        </thead>
        <tbody>
          {
            searchResults?.filter(f => f.isDirectory === false).map((f) => {
              return <tr className={f.isDirectory ? 'directory-row' : 'file-row'}>
                <td></td>
                <td style={{ textAlign: "center" }}>{f.isDirectory ? <FcFolder /> : <FcFile />}</td>
                <td
                  className={f.isDirectory ? 'td-directory' : 'td-file'}>{
                    f.isDirectory
                      ? f.name
                      : <a href={`${window.location.host}/fichiers/${f.id}`} download={f.name} target='_blank' rel='noreferrer' >{f.name}</a>
                  }</td>
                <td>{this.epochToLocalTime(Number.parseInt(f.dateModification))}</td>
                <td>{f.size ? this.toHumanFileSize(Number.parseInt(f.size)) : ""}</td>
              </tr>
            })
          }
        </tbody>
      </table>
    </div>
  }




  renderDeleteDialog(): React.ReactNode {
    const { selectFiles, currentPath } = this.state;
    return <Dialog
      title={i18n.s("fileDeletion")}
      showCloseButton={true}
      showOkButton={true}
      showCancelButton={true}
      onClose={() => {
        this.buildFileTable(currentPath);
      }}
      onCancel={() => {
        this.buildFileTable(currentPath);
      }}
      onOK={async () => {
        showLoading(true);
        const { currentPath } = this.state;
        const promises = [];
        for (var fileID of selectFiles) {
          promises.push(await api.file.deleteFile(fileID));
        }
        const results = await Promise.all(promises);
        var success = 0;
        var failed = 0;
        for (var r of results) {
          if (typeof r !== "boolean") {
            failed++;
          }
          else {
            success++;
          }
        }
        this.buildFileTable(currentPath);
        if (failed > 0) {
          showError(i18n.s("deleteFileFailedCount", [failed.toString(), (failed + success).toString()]));
        }
        else {
          showSuccess(i18n.s('success'));
        }
        showLoading(false);
      }}
    >
      {
        i18n.s("deleteFileConfirm", [selectFiles.length.toString()])
      }
    </Dialog>
  }


  renderCreateDirectoryDialog(): React.ReactNode {
    const { newFolderName, currentPath } = this.state;
    return <Dialog
      title={i18n.s("createDirectory")}
      showCloseButton={true}
      showOkButton={true}
      showCancelButton={true}
      onClose={() => {
        this.buildFileTable(currentPath);
      }}
      onCancel={() => {
        this.buildFileTable(currentPath);
      }}
      onOK={async () => {
        showLoading(true);
        const { currentPath } = this.state;
        const result: any = await api.file.createDirectory(currentPath !== "" ? `${currentPath}/${newFolderName}` : newFolderName);
        if (typeof result !== "boolean") {
          showError(result);
        }
        else {
          showSuccess(i18n.s('success'));
        }
        this.buildFileTable(currentPath, true);
        showLoading(false);
      }}
    >
      <div>
        <div className='input-with-label'>
          <div><BsVectorPen /> <label>{i18n.s('enterFolderName')}</label></div>
          <input type="text" value={newFolderName} placeholder={i18n.s("enterFolderName")} onChange={(e) => { this.setState({ newFolderName: e.currentTarget.value }) }} />
        </div>
      </div>
    </Dialog>
  }


  renderUploadFileDialog(): React.ReactNode {
    const { currentPath } = this.state;
    return <Dialog
      title={i18n.s("uploadFileDialog")}
      showCloseButton={true}
      showOkButton={true}
      showCancelButton={true}
      onClose={() => {
        this.buildFileTable(currentPath);
      }}
      onCancel={() => {
        this.buildFileTable(currentPath);
      }}
      onOK={async () => {
        const { uploadFiles, currentPath } = this.state;
        this.setState({
          showUploadFileDialog: false,
          uploadFiles: null
        });
        const result: any = await api.file.uploadFiles(currentPath, uploadFiles, (progress: number, KBps: number, remainingSeconds: number) => {
          this.setState({
            uploadProgress: progress,
            uploadRemainingSeconds: remainingSeconds,
            uploadBps: KBps,
          })
        });
        if (typeof result !== "boolean") {
          showError(result);
        }
        else {
          showSuccess(i18n.s('success'));
        }
        this.buildFileTable(currentPath);
      }}
    >
      <div>
        <input multiple type="file" onChange={(e) => { this.setState({ uploadFiles: e.currentTarget.files }) }} />
      </div>
    </Dialog>
  }


  render() {
    const {
      search,
      dirTree, fileList, expanded, currentPath,
      showDeleteFilesDialog, selectAllFiles, selectFiles,
      showNewFolderDialog,
      showUploadFileDialog, uploadProgress, uploadRemainingSeconds, uploadBps,
      currentDirID
    } = this.state;

    return (
      <div className='management file-explorer'>
        <h1>{i18n.s("fileExplorer")}</h1>
        <div className='search'>
          <div className='input-with-label'>
            <div><VscSearch /></div>
            <input type="text" value={search} placeholder={i18n.s('search')} onChange={(e) => { this.searchFiles(e.currentTarget.value); }} />
          </div>
        </div>
        {
          search === ""
            ? <div id="file-explorer-main">

              <div id="file-explorer-folders-list">
                {
                  dirTree === null || dirTree.childrens === undefined
                    ? null
                    : <SimpleTreeView
                      multiSelect={false}
                      expandedItems={expanded}
                      selectedItems={currentPath}
                      slots={{ collapseIcon: MdExpandMore, expandIcon: MdChevronRight }}
                      sx={{ height: "100%", flexGrow: 1, overflowY: 'auto' }}
                    >
                      {
                        this.renderTreeDirectories(dirTree)
                      }
                    </SimpleTreeView>
                }
              </div>
              <div id="file-explorer-files-list">
                <div id="file-explorer-menu" className='button-actions'>
                  <button onClick={() => { this.buildFileTable(currentDirID, true) }} title={i18n.s("reload")}><VscRefresh /> </button>
                  {
                    hasAnyPrivileges(api.currentUser, [EPrivileges.SUPER_ADMIN, EPrivileges.WRITE_FILES])
                      ? <Fragment>
                        <button onClick={() => { this.setState({ showNewFolderDialog: true, newFolderName: '' }) }} title={i18n.s("newFolder")}>
                          <VscNewFolder />
                        </button>
                        <button onClick={() => { this.setState({ showUploadFileDialog: true, uploadFiles: null }) }} title={i18n.s("uploadFileDialog")}>
                          <VscCloudUpload />
                        </button>
                        <button className='danger' disabled={selectFiles.length === 0} onClick={() => { this.setState({ showDeleteFilesDialog: true }) }} title={i18n.s("delete")}>
                          <IoTrashBinOutline />
                        </button>
                      </Fragment>
                      : null
                  }
                </div>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "24px", textAlign: "center" }}>
                        <input
                          type="checkbox"
                          checked={selectAllFiles}
                          onChange={() => {
                            if (selectAllFiles) {
                              //we must remove all files 
                              this.setState({
                                selectAllFiles: false,
                                selectFiles: []
                              })
                            }
                            else {
                              //we must select all files
                              this.setState({
                                selectAllFiles: true,
                                selectFiles: fileList !== null ? fileList.map(sf => sf.id) : []
                              })
                            }
                          }}
                        />
                      </th>
                      <th onClick={() => { this.sortObjectsBy("isDirectory") }} style={{ width: "24px", textAlign: "center" }}></th>
                      <th onClick={() => { this.sortObjectsBy("name") }} style={{ minWidth: "150px" }}>{i18n.s("fileName")}</th>
                      <th onClick={() => { this.sortObjectsBy("dateModification") }} style={{ minWidth: "180px", width: '180px', maxWidth: "180px" }}>{i18n.s("modified")}</th>
                      <th onClick={() => { this.sortObjectsBy("size") }} style={{ minWidth: "100px", width: '100px', maxWidth: "100px" }}>{i18n.s("size")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      currentPath !== ""
                        ? <tr className='directory-row'
                          onClick={() => {
                            this.goBack();
                          }}
                        >
                          <td></td>
                          <td style={{ textAlign: "center" }}><FcFolder /></td>
                          <td className='td-directory'>..</td>
                          <td></td>
                        </tr>
                        : null

                    }
                    {
                      fileList !== null
                        ? fileList.map((f) => {
                          return <tr
                            className={f.isDirectory ? 'directory-row' : 'file-row'}
                            key={f.id}
                            data-time={f.dateModification}
                            data-size={f.size}
                          >
                            <td style={{ textAlign: "center" }}>
                              <input type='checkbox'
                                checked={selectFiles.findIndex(delId => delId === f.id) > -1}
                                onChange={(e) => {
                                  if (e.currentTarget.checked) {
                                    this.setState({
                                      selectAllFiles: false,
                                      selectFiles: [...selectFiles, f.id]
                                    })
                                  }
                                  else {
                                    this.setState({
                                      selectAllFiles: false,
                                      selectFiles: selectFiles.filter(delId => delId !== f.id)
                                    })
                                  }
                                }} />
                            </td>
                            <td style={{ textAlign: "center" }}>{f.isDirectory ? <FcFolder /> : <FcFile />}</td>
                            <td
                              draggable={f.isDirectory === false}
                              onDragStart={(event) => {
                                event.dataTransfer.setData('application/x-file-id', JSON.stringify(f));
                                event.dataTransfer.effectAllowed = 'move';
                              }}
                              onClick={() => {
                                if (f.isDirectory) {
                                  if (f.parent !== "" && f.parent !== null) {
                                    this.expandDir(f.parent);
                                  }
                                  this.expandDir(f.id);
                                  this.buildFileTable(f.id);
                                }
                              }}
                              className={f.isDirectory ? 'td-directory' : 'td-file'}>{
                                f.isDirectory
                                  ? f.name
                                  : <a href={`${window.location.protocol}//${window.location.host}/fichiers/${f.id}`} download={f.name} target='_blank' rel='noreferrer' >{f.name}</a>
                              }</td>
                            <td>{this.epochToLocalTime(f.dateModification)}</td>
                            <td>{f.size ? this.toHumanFileSize(f.size) : ""}</td>
                          </tr>
                        })
                        : null
                    }
                  </tbody>
                </table>
              </div>
            </div>
            : null
        }

        {
          search !== ""
            ? this.renderSearchResults()
            : null
        }

        {
          showDeleteFilesDialog ? this.renderDeleteDialog() : null
        }
        {
          showNewFolderDialog ? this.renderCreateDirectoryDialog() : null
        }
        {
          showUploadFileDialog ? this.renderUploadFileDialog() : null
        }
        {
          uploadProgress !== null
            ? <Dialog title='Envoi en cours' showOkButton={false} showCancelButton={false} showCloseButton={false}>
              <div className='file-upload-progress'>
                <label className='bigger'>{`${uploadProgress}%`}</label>
                <label>{`${this.toHumanFileSize(uploadBps)}/s`}</label>
                <label>{`${this.toHumanDuration(uploadRemainingSeconds)} ${i18n.s("remaiming")}`}</label>
                <progress style={{ width: "100%" }} value={uploadProgress} max={100}></progress>
              </div>
            </Dialog>
            : null
        }

      </div >
    );
  }
}


export default FileExplorer;