import React, { Component, Fragment } from 'react';
import '../../styles/support.scss';
import '../../styles/managementPage.scss';
import '../../styles/toolTip.scss';
import { IPageProps, E_SORTORDER, sortObjects } from '../../sharedInterfaces';
import { api, showInfo, showSuccess, showError, showLoading } from '../../sharedInterfaces';
import i18n from '../../i18n/I18n';
import { ICustomer, IReseller, IXorcomLicense } from '../../apitypes/index';
import moment from 'moment';
import ToolTip from '../../comps/toolTip/ToolTip';
import EditXorcomLicenseForm from './EditXorcomLicenseForm';
import { VscSearch, VscRefresh } from 'react-icons/vsc';
import { RxPencil1 } from "react-icons/rx";
import { IoMdCopy } from "react-icons/io";
import { BsPlus } from 'react-icons/bs';
import { TbMailDollar } from "react-icons/tb";
import Dialog from '../../comps/dialog/Dialog';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';

export interface IFilter {
  inactives: boolean,
  actives: boolean,
  unexpired: boolean,
  expired: boolean,
  maxExpireDays: number
}

interface IState {
  search: string,
  resellers: IReseller[],
  customers: ICustomer[],
  licenses: IXorcomLicense[],
  filteredLicenses: IXorcomLicense[],
  showEditLicense: boolean,
  editLicense: IXorcomLicense | null,
  sortBy: string,
  sortOrder: E_SORTORDER,
  tooltip: string | null,
  tooltipX: number,
  tooltipY: number,
  filter: IFilter,
  showLicenseRenewMail: IXorcomLicense | null,
  resellerMail: string,
  showResultCount: number,
  page: number
}

class XorcomLicensesManagement extends Component<IPageProps, IState> {
  constructor(props: IPageProps) {
    super(props);

    this.state = {
      search: '',
      resellers: [],
      customers: [],
      licenses: [],
      filteredLicenses: [],
      showEditLicense: false,
      editLicense: null,
      sortBy: 'system',
      sortOrder: E_SORTORDER.ASC,
      tooltip: null,
      tooltipX: 0,
      tooltipY: 0,
      filter: {
        inactives: true,
        actives: true,
        unexpired: true,
        expired: true,
        maxExpireDays: 0
      },
      showLicenseRenewMail: null,
      resellerMail: '',
      showResultCount: 50,
      page: 1
    }
  }

  async componentDidMount(): Promise<void> {
    this.reloadObjects();
  }

  async sortObjectsBy(sortBy: string) {
    const { licenses, sortOrder } = this.state;
    showLoading(true);

    let newSortOrder: E_SORTORDER = E_SORTORDER.ASC;
    if (sortBy === this.state.sortBy) {
      //Change sort order
      if (sortOrder === E_SORTORDER.ASC) {
        newSortOrder = E_SORTORDER.DESC;
      }
    }

    this.setState({
      sortOrder: newSortOrder,
      sortBy: sortBy,
      licenses: sortObjects(licenses, sortBy, newSortOrder)
    }, () => {
      this.filterLicenses()
    });
    showLoading(false);
  }

  async reloadObjects() {

    showLoading(true);
    this.setState({
      resellers: await api.reseller.getResellers(),
      customers: await api.customer.getCustomers(),
      licenses: await api.xorcomLicenses.getLicenses()
    }, () => {
      this.filterLicenses();
    });
    showLoading(false);
  }


  filterLicenses() {
    const { licenses, filter, customers, resellers } = this.state;

    const filteredLicenses = licenses.filter((obj) => {
      if (filter.inactives === false && obj.active === 0) {
        return false; //on veut cacher les inactives
      }
      if (filter.actives === false && obj.active !== 0) {
        return false; //on veut cacher les actives
      }
      const expired = obj.validUntil > 0 ? moment().unix() > obj.validUntil : false;
      if (filter.unexpired === false && expired === false) {
        return false; //on veut cacher les non expirées
      }
      if (filter.expired === false && expired) {
        return false; //on veut cacher les expirées
      }
      const daysBeforeExpired = obj.validUntil > 0 ? Math.ceil((obj.validUntil - moment().unix()) / 86400) : Number.MAX_VALUE;
      if (filter.maxExpireDays > 0 && daysBeforeExpired > filter.maxExpireDays) {
        return false;
      }

      const search = this.state.search.trim().toUpperCase();
      if (search !== "") {
        let reseller: IReseller | null = null;
        let customer: ICustomer | null = null;
        if (obj.customer) {
          customer = customers.find(c => c.id === obj.customer) || null;
          reseller = customer !== null ? resellers.find(r => r.id === customer?.reseller) || null : null;
        }
        else if (obj.reseller) {
          reseller = resellers.find(r => r.id === obj.reseller) || null;
        }

        let doNoDisplay = true;
        if (reseller && reseller.company.toUpperCase().includes(search)) {
          doNoDisplay = false;
        }
        if (reseller && reseller.siret.toUpperCase().includes(search)) {
          doNoDisplay = false;
        }
        if (customer && customer.company.toUpperCase().includes(search)) {
          doNoDisplay = false;
        }
        if (customer && customer.siret.toUpperCase().includes(search)) {
          doNoDisplay = false;
        }
        if (obj.key.toUpperCase().includes(search)) {
          doNoDisplay = false;
        }
        if (obj.product.toUpperCase().includes(search)) {
          doNoDisplay = false;
        }
        if (obj.type.toUpperCase().includes(search)) {
          doNoDisplay = false;
        }
        if (obj.comment.toUpperCase().includes(search)) {
          doNoDisplay = false;
        }
        if (obj.system.toUpperCase().includes(search)) {
          doNoDisplay = false;
        }
        if (obj.ownedBy.toUpperCase().includes(search)) {
          doNoDisplay = false;
        }
        if (doNoDisplay) {
          return false;
        }
      }
      return true;
    })

    this.setState({
      filteredLicenses
    });

  }


  renderObjectRow(obj: IXorcomLicense): React.ReactNode {
    const { resellers, customers } = this.state;

    const daysBeforeExpired = obj.validUntil > 0 ? Math.ceil((obj.validUntil - moment().unix()) / 86400) : Number.MAX_VALUE;

    let reseller: IReseller | null = null;
    let customer: ICustomer | null = null;
    if (obj.customer) {
      customer = customers.find(c => c.id === obj.customer) || null;
      reseller = customer !== null ? resellers.find(r => r.id === customer?.reseller) || null : null;
    }
    else if (obj.reseller) {
      reseller = resellers.find(r => r.id === obj.reseller) || null;
    }

    const lastMailDays = obj.lastMail > 0 ? Math.ceil((moment().unix() - obj.lastMail) / 86400) : Number.MAX_VALUE;
    return <tr key={obj.key} className={daysBeforeExpired <= 30 ? 'danger' : undefined} >
      <td>
        <div className='buttons'>
          <button onClick={() => {
            this.setState({
              showEditLicense: true,
              editLicense: obj
            })
          }}><RxPencil1 /></button>
          {
            reseller && daysBeforeExpired <= 30 && lastMailDays >= 7
              ? <button onClick={() => {
                this.setState({
                  showLicenseRenewMail: obj,
                  resellerMail: `${api.currentUser?.email}\n${reseller?.email.split(',')}`
                });
              }}><TbMailDollar /></button>
              : null
          }
        </div>
      </td>
      <td>{reseller ? reseller.company : ''}</td>
      <td>{customer ? customer.company : ''}</td>
      <td>
        <div className='inline'>
          <button onClick={async () => {
            await navigator.clipboard.writeText(obj.key);
            showSuccess(i18n.s('licenseCopiedToClipboard', [obj.key]))
          }}><IoMdCopy /></button>
          <label>{obj.key}</label>
        </div>
      </td>
      <td>{obj.product}</td>
      <td>{obj.system}</td>
      <td>{obj.active ? i18n.s('yes') : i18n.s('no')}</td>
      <td>{obj.validAfter > 0 ? i18n.s('yes') : i18n.s('no')}</td>
      <td>{moment.unix(obj.created).format('DD/MM/YYYY HH:mm:ss')}</td>
      <td>{obj.validUntil > 0 ? `${daysBeforeExpired} ${i18n.s('days')}` : '∞'}</td>
      <td>{obj.validUntil > 0 ? `${moment.unix(obj.validUntil).format('DD/MM/YYYY')}` : '∞'}</td>
      <td>
        <label onMouseEnter={(e) => {
          this.setState({
            tooltip: obj.comment,
            tooltipX: e.clientX,
            tooltipY: e.clientY
          })
        }} onMouseLeave={() => {
          this.setState({
            tooltip: null
          })
        }}>
          {obj.comment.trim().length > 10 ? obj.comment.substring(0, 10) + "..." : obj.comment}
        </label>
      </td>
      <td>{obj.lastMail > 0 ? moment.unix(obj.lastMail).format("DD/MM/YYYY") : ''}</td>
    </tr>
  }



  renderLicenseRenewMail() {
    const { showLicenseRenewMail, resellerMail, resellers, customers } = this.state;

    if (showLicenseRenewMail === null) {
      return null;
    }

    const daysBeforeExpired = showLicenseRenewMail.validUntil > 0 ? Math.ceil((showLicenseRenewMail.validUntil - moment().unix()) / 86400) : Number.MAX_VALUE;

    let reseller: IReseller | null = null;
    let customer: ICustomer | null = null;
    if (showLicenseRenewMail.customer) {
      customer = customers.find(c => c.id === showLicenseRenewMail.customer) || null;
      reseller = customer !== null ? resellers.find(r => r.id === customer?.reseller) || null : null;
    }
    else if (showLicenseRenewMail.reseller) {
      reseller = resellers.find(r => r.id === showLicenseRenewMail.reseller) || null;
    }

    if (reseller === null) {
      showError(i18n.s('renewLicenseErrorNoReseller'));
      return null;
    }

    return <Dialog
      title={i18n.s("licenseRenewMail")}
      maxWidth='800px'
      maxHeight='600px'
      onCancel={() => { this.setState({ showLicenseRenewMail: null }) }}
      onOK={async () => {
        this.setState({ showLicenseRenewMail: null }) //close immediatly, we send in background
        const mailSent = await api.xorcomLicenses.sendRenewMail(showLicenseRenewMail, resellerMail.split('\n'));
        if (mailSent) {
          showSuccess(i18n.s('mailSent'));
        }
        else {
          showError(i18n.s('mailError'));
        }
      }}
      showOkButton={true}
      showCancelButton={true}
      showCloseButton={false}
    >
      <div>
        <h5>{i18n.s('sendLicenseRenewMail')}</h5>
        <p>{i18n.s('confirmSendExpirationMail')}</p>
        <div className='columns'>
          <div>
            <label>{i18n.s('licenseKey')}</label>
            <label>{showLicenseRenewMail.key}</label>
          </div>
          <div>
            <label>{i18n.s('licenseSystem')}</label>
            <label>{showLicenseRenewMail.system}</label>
          </div>
          <div>
            <label>{i18n.s('licenseFirstRegistration')}</label>
            <label>{showLicenseRenewMail.firstRegistration > 0 ? moment.unix(showLicenseRenewMail.firstRegistration).format('DD/MM/YYYY') : ''}</label>
          </div>
          <div>
            <label>{i18n.s('licenseValidUntil')}</label>
            <label>{showLicenseRenewMail.validUntil > 0 ? moment.unix(showLicenseRenewMail.validUntil).format('DD/MM/YYYY') : ''}</label>
          </div>
          <div>
            <label>{i18n.s('licenseExpireInDays')}</label>
            <label>{daysBeforeExpired} {i18n.s('days')}</label>
          </div>
          <div>
            <label>{i18n.s('reseller')}</label>
            <label>{reseller.company}</label>
          </div>
          <div>
            <label>{i18n.s('emailTo')}</label>
            <label>
              <textarea style={{ minWidth: '300px', width: "100%", minHeight: '80px', resize: 'vertical' }} value={resellerMail} onChange={(e) => { this.setState({ resellerMail: e.currentTarget.value.replace(/,/g, "\n").replace(/;/g, "\n").replace(/\s/g, '\n') }) }} ></textarea>
            </label>
          </div>
        </div>
      </div>
    </Dialog>
  }




  render() {
    const { filteredLicenses, editLicense, showEditLicense, search, tooltip, tooltipX, tooltipY, filter, showLicenseRenewMail, showResultCount, page } = this.state;

    const displayLicenses = filteredLicenses.slice(((page - 1) * showResultCount), ((page * showResultCount) - 1));


    return (
      <Fragment>
        <div className='management'>
          <h1>{i18n.s("xorcomLicenses")}</h1>
          <div className='search'>
            <div className='input-with-label'>
              <div><VscSearch /></div>
              <input type="text" value={search} placeholder={i18n.s('search')} onChange={(e) => {
                this.setState({
                  search: e.currentTarget.value
                }, () => {
                  this.filterLicenses();
                })
              }} />
            </div>
            <div className='search-options'>

              <span>
                <input type="checkbox" checked={filter.inactives} onChange={(e) => {
                  this.setState({
                    filter: {
                      ...filter,
                      inactives: e.currentTarget.checked
                    }
                  }, () => {
                    this.filterLicenses();
                  })
                }} />
                <label>{i18n.s('licenseShowInactives')}</label>
              </span>

              <span>
                <input type="checkbox" checked={filter.actives} onChange={(e) => {
                  this.setState({
                    filter: {
                      ...filter,
                      actives: e.currentTarget.checked
                    }
                  }, () => {
                    this.filterLicenses();
                  })
                }} />
                <label>{i18n.s('licenseShowActives')}</label>
              </span>

              <span>
                <input type="checkbox" checked={filter.unexpired} onChange={(e) => {
                  this.setState({
                    filter: {
                      ...filter,
                      unexpired: e.currentTarget.checked
                    }
                  }, () => {
                    this.filterLicenses();
                  })
                }} />
                <label>{i18n.s('licenseShowUnexpired')}</label>
              </span>

              <span>
                <input type="checkbox" checked={filter.expired} onChange={(e) => {
                  this.setState({
                    filter: {
                      ...filter,
                      expired: e.currentTarget.checked
                    }
                  }, () => {
                    this.filterLicenses();
                  })
                }} />
                <label>{i18n.s('licenseShowExpired')}</label>
              </span>

              <span>
                <label>{i18n.s('maxExpireDays')}</label>
                <input type="number" style={{ width: '80px' }} min={0} max={Number.MAX_SAFE_INTEGER} value={filter.maxExpireDays} onChange={(e) => {
                  this.setState({
                    filter: {
                      ...filter,
                      maxExpireDays: parseInt(e.currentTarget.value)
                    }
                  }, () => {
                    this.filterLicenses();
                  })
                }} />
              </span>

            </div>
          </div>

          <div className='button-actions'>
            <button title={i18n.s("reload")} onClick={() => { this.reloadObjects() }}><VscRefresh /></button>
            <button title={i18n.s("createLicense")} className='success' onClick={() => {
              this.setState({
                editLicense: null,
                showEditLicense: true
              })
            }}><BsPlus /></button>

            <select value={showResultCount} onChange={(e) => {
              this.setState({
                showResultCount: parseInt(e.currentTarget.value),
                page: 1
              })
            }}>
              <option value={50}>{i18n.s('showResultCount', ["50"])}</option>
              <option value={100}>{i18n.s('showResultCount', ["100"])}</option>
              <option value={200}>{i18n.s('showResultCount', ["200"])}</option>
              <option value={300}>{i18n.s('showResultCount', ["300"])}</option>
              <option value={500}>{i18n.s('showResultCount', ["500"])}</option>
            </select>

            <button onClick={() => { this.setState({ page: page - 1 }) }} disabled={page === 1}><MdArrowBack /></button>
            <label>{i18n.s('licensePage', [page.toString(), Math.ceil(filteredLicenses.length / showResultCount).toString()])}</label>
            <button onClick={() => { this.setState({ page: page + 1 }) }} disabled={page === Math.ceil(filteredLicenses.length / showResultCount)}><MdArrowForward /></button>
          </div>

          <div>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th onClick={() => { this.sortObjectsBy("reseller") }}>{i18n.s("reseller")}</th>
                  <th onClick={() => { this.sortObjectsBy("customer") }}>{i18n.s("customer")}</th>
                  <th onClick={() => { this.sortObjectsBy("key") }}>{i18n.s("licenseKey")}</th>
                  <th onClick={() => { this.sortObjectsBy("product") }}>{i18n.s("licenseProduct")}</th>
                  <th onClick={() => { this.sortObjectsBy("system") }}>{i18n.s("licenseSystem")}</th>
                  <th onClick={() => { this.sortObjectsBy("active") }}>{i18n.s("licenseActive")}</th>
                  <th onClick={() => { this.sortObjectsBy("validAfter") }}>{i18n.s("licenseRegistered")}</th>
                  <th onClick={() => { this.sortObjectsBy("created") }}>{i18n.s("licenseCreated")}</th>
                  <th onClick={() => { this.sortObjectsBy("validUntil") }}>{i18n.s("licenseExpireInDays")}</th>
                  <th onClick={() => { this.sortObjectsBy("validUntil") }}>{i18n.s("licenseValidUntil")}</th>
                  <th onClick={() => { this.sortObjectsBy("comment") }}>{i18n.s("licenseComment")}</th>
                  <th onClick={() => { this.sortObjectsBy("lasMail") }}>{i18n.s("licenseLastMail")}</th>
                </tr>
              </thead>
              <tbody>
                {
                  displayLicenses.map((obj) => {
                    return this.renderObjectRow(obj)
                  })
                }
              </tbody>
            </table>
          </div>



          {
            tooltip ? <ToolTip text={tooltip} x={tooltipX} y={tooltipY} /> : null
          }

          {
            showEditLicense
              ? <EditXorcomLicenseForm
                editLicenseKey={editLicense ? editLicense.key : null}
                onCancel={() => {
                  this.setState({
                    showEditLicense: false,
                    editLicense: null
                  })
                }}
                onSave={async (key: string | null) => {
                  this.setState({
                    showEditLicense: false,
                    editLicense: null
                  });
                  if (key) {
                    this.setState({
                      search: key
                    });
                  }
                  this.reloadObjects();
                }}
              />
              : null
          }

          {
            showLicenseRenewMail
              ? this.renderLicenseRenewMail()
              : null
          }

        </div>
      </Fragment >

    );
  }
}

export default XorcomLicensesManagement;