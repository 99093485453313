import React, { Component } from 'react';

interface IProps {
  text: string,
  x: number,
  y: number
}

interface IState {
  x: number,
  y: number
}

class ToolTip extends Component<IProps, IState> {
  mouseUpdateCallback;

  constructor(props: IProps) {
    super(props);

    this.state = {
      x: this.props.x + 10,
      y: this.props.y + 10
    }

    this.mouseUpdateCallback = (mouseEvent: MouseEvent) => {
      this.setState({
        x: mouseEvent.pageX + 10,
        y: mouseEvent.pageY + 10
      });
    }
  }

  componentDidMount(): void {
    window.document.addEventListener('mousemove', this.mouseUpdateCallback);
  }

  componentWillUnmount(): void {
    window.document.removeEventListener('mousemove', this.mouseUpdateCallback);
  }

  render() {
    const { text } = this.props;
    const { x, y } = this.state;
    return (
      <div className='tooltip' style={{ position: 'fixed', left: `${x}px`, top: `${y}px` }}>
        {text.split('\n').map(s => <div>{s}</div>)}
      </div>
    );
  }
}

export default ToolTip;