import React, { Component } from 'react';

export interface IMenuItem {
  index: string,
  label: string,
  icon: React.ReactNode,
  onSelected?: Function,
  component?: React.ReactNode,
  show: boolean,
  color?: string,
  backgroundColor?: string,
}

interface IProps {
  items: Array<IMenuItem>,
  selected: string,
  onChange: Function
}

interface IState {
  expanded: boolean
}

class Menu extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      expanded: false
    }
  }


  render(): React.ReactNode {
    const { items, selected, onChange } = this.props;
    const { expanded } = this.state;

    return (
      <div 
        className={`menu ${expanded ? 'expanded' : ''}`}
        onMouseEnter={() => { this.setState({ expanded: true })}}
        onMouseLeave={() => { this.setState({ expanded: false })}}
      >
        <ul >
          {
            items.filter(i => i.show).map((item) => {
              return <li
                title={expanded ? '' : item.label}
                key={item.index}
                className={selected === item.index ? 'menu-item menu-item-selected' : 'menu-item'}
                onClick={() => {
                  if (item.onSelected) {
                    item.onSelected();
                  }
                  onChange(item)
                }}
                style={{
                  color: item.color,
                  backgroundColor: item.backgroundColor
                }}
              >
                {item.icon}
                <label>{item.label}</label>
              </li>;
            })
          }
        </ul>
      </div>

    );
  }
}

export default Menu;