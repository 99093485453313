export interface IUploadFile {
  type: string,
  file: File
}

export async function GetAPI(uri: string, token: string, onAuthErrorCallback: Function | null = null, method = "GET"): Promise<Response> {
  console.log(`Sending ${method} request to ${uri}`);
  let response = await fetch(`${uri}`, {
    method,
    headers: {
      'Authorization': token
    }
  });
  if (response.status === 403) {
    if (onAuthErrorCallback) {
      onAuthErrorCallback();
    }
  }

  return response;
}

export async function PostAPI(uri: string, token: string, data: any, onAuthErrorCallback: Function | null = null, method = "POST"): Promise<Response> {
  console.log(`Sending ${method} request to ${uri}`, data);
  let response = await fetch(`${uri}`, {
    method,
    headers: {
      'Content-type': 'application/json',
      'Authorization': token
    },
    body: JSON.stringify(data ? data : {})
  });
  if (response.status === 403) {
    if (onAuthErrorCallback) {
      onAuthErrorCallback();
    }
  }

  return response;
}

export async function PutAPI(uri: string, token: string, data: any, onAuthErrorCallback: Function | null = null): Promise<Response> {
  return await PostAPI(uri, token, data ? data : {}, onAuthErrorCallback, "PUT");
}

export async function DeleteAPI(uri: string, token: string, onAuthErrorCallback: Function | null = null): Promise<Response> {
  return await GetAPI(uri, token, onAuthErrorCallback, "DELETE");
}

/**
 * Upload file to server.
 * 
 * @param uri 
 * @param token 
 * @param files 
 * @param onProgressCallback 
 * @param onAuthErrorCallback 
 * @returns 
 */
export async function UploadAPI(uri: string, token: string, files: Array<IUploadFile>, onProgressCallback: Function, onAuthErrorCallback: Function | null = null): Promise<string> {
  return new Promise((resolve) => {
    console.log(`Uploading files to ${uri}`, files);

    const startedAt = new Date();
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (e) => {
      const percent = Math.round((e.loaded / e.total) * 100);
      var elapsed = (new Date().getTime() - startedAt.getTime()) / 1000;
      var Bps = elapsed ? e.loaded / elapsed : 0;
      var remainingBytes = e.total - e.loaded;
      var remainingSeconds = elapsed ? Math.round(remainingBytes / Bps) : 0;
      onProgressCallback(percent, Bps, remainingSeconds);
    });
    xhr.addEventListener('load', () => {
      if (xhr.status === 403) {
        if (onAuthErrorCallback) {
          onAuthErrorCallback();
        }
      }
      resolve(xhr.responseText);
    });
    xhr.addEventListener('error', () => resolve(JSON.stringify({
      status: 'error',
      error: "File upload failed"
    })));
    xhr.addEventListener('abort', () => resolve(JSON.stringify({
      status: 'error',
      error: "File upload aborted"
    })));

    xhr.open('POST', `${uri}`, true);
    xhr.setRequestHeader('Authorization', token);
    const formData = new FormData();
    Array.from(files).forEach((file, index) => formData.append(file.type, file.file));
    xhr.send(formData);
  });
}

export async function DownloadAPI(uri: string, token: string, onProgressCallback: Function, onAuthErrorCallback: Function | null = null): Promise<any> {
  return new Promise((resolve) => {
    console.log(`Downloading file from ${uri}`);

    const startedAt = new Date();
    const xhr = new XMLHttpRequest();
    xhr.upload.addEventListener('progress', (e) => {
      if (e.lengthComputable) {
        const percent = Math.round((e.loaded / e.total) * 100);
        var elapsed = (new Date().getTime() - startedAt.getTime()) / 1000;
        var Bps = elapsed ? e.loaded / elapsed : 0;
        var remainingBytes = e.total - e.loaded;
        var remainingSeconds = elapsed ? Math.round(remainingBytes / Bps) : 0;
        onProgressCallback(percent, Bps, remainingSeconds);
      }
    });
    xhr.addEventListener('load', () => {
      if (xhr.status === 403) {
        if (onAuthErrorCallback) {
          return onAuthErrorCallback();
        }
      }
      if (xhr.status !== 200) {
        resolve({
          status: 'error',
          error: xhr.status
        });
      }
      if (xhr.status === 200) {
        const blob = xhr.response;
        resolve({
          status: "success",
          data: blob
        });
      }
    });
    xhr.addEventListener('error', () => resolve({
      status: 'error',
      error: "File upload failed"
    }));
    xhr.addEventListener('abort', () => resolve({
      status: 'error',
      error: "File upload aborted"
    }));

    xhr.open('GET', `${uri}`, true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader('Authorization', token);
    xhr.send();
  });
}
